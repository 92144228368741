
import { computed, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'
import { ImageSizes } from '@zaprooecommerce/image'
import { imgPreload } from '~/components/directives/img-preload.directive'

const SizesConfig = {
  mobile: ['xs', 'sm'],
  tablet: ['md', 'lg']
}

export default defineComponent({
  name: 'ResponsiveImage',
  directives: {
    imgPreload
  },
  props: {
    desktopImage: {
      type: String,
      required: true
    },
    tabletImage: {
      type: String,
      required: false,
      default: undefined
    },
    mobileImage: {
      type: String,
      required: false,
      default: undefined
    },
    sizes: {
      type: String,
      required: false,
      default: undefined
    },
    densities: {
      type: Array as PropType<number[]>,
      required: false,
      default: () => [1, 2]
    },
    format: {
      type: String,
      required: false,
      default: undefined
    },
    fit: {
      type: String,
      required: false,
      default: undefined
    },
    preload: {
      type: [String, Object, Boolean],
      required: false,
      default: undefined
    },
    alt: {
      type: String,
      required: false,
      default: undefined
    },
    loading: {
      type: String,
      required: false,
      default: undefined
    }
  },

  setup (props) {
    const { $img } = useContext()
    const { options: { screens } } = $img

    const tabletImage = computed(() => props.tabletImage ?? props.desktopImage)
    const mobileImage = computed(() => (props.mobileImage || props.tabletImage) ?? props.desktopImage)

    const images = computed(() => ({
      mobile: getImage(mobileImage.value),
      tablet: getImage(tabletImage.value),
      desktop: getImage(props.desktopImage)
    }))

    const srcset = computed(() => prepareSrcSet())

    const srcsetSizes = computed(() => images.value.desktop.sizes)

    function getImage (imageSrc: string): ImageSizes & {srcsetParsed: ReturnType<typeof parseSrcset>} {
      const image = $img.getSizes(imageSrc, {
        sizes: props.sizes,
        densities: props.densities.map(n => `${n}x`).join(' '),
        modifiers: {
          format: props.format,
          fit: props.fit
        }
      })

      return { ...image, srcsetParsed: parseSrcset(image.srcset) }
    }

    function prepareSrcSet ():string {
      let srcset = images.value.desktop.srcset
      const parsedSrcset = images.value.desktop.srcsetParsed

      for (const { path, size, sizeType } of parsedSrcset) {
        if (!sizeType) {
          continue
        }

        const targetImage = images.value[sizeType]

        if (!targetImage) {
          continue
        }

        const itemToReplace = targetImage.srcsetParsed.find(el => el.sizeType === sizeType && el.size === size)

        if (itemToReplace) {
          srcset = srcset.replace(path, itemToReplace.path)
        }
      }

      return srcset
    }

    function parseSrcset (srcset:string) {
      const result: {item:string, path: string, size: number, sizeType?: keyof typeof SizesConfig}[] = []

      for (const match of srcset.matchAll(/(.+?) (\d+w),?/gm)) {
        const [, path, size] = match
        const sizePx = +size.replace('w', '')
        // detect size type
        const sizeType = Object.entries(SizesConfig)
          .find(([_key, stageSizes]) => {
            const stageSizesPx = stageSizes.map(size => screens[size])
            return props.densities.some(d => stageSizesPx.includes(sizePx / d))
          })?.[0]

        result.push({
          item: `${path.trim()} ${size.trim()}`,
          path: path.trim(),
          size: sizePx,
          sizeType: sizeType as keyof typeof SizesConfig
        })
      }

      return result
    }

    return {
      srcset,
      srcsetSizes
    }
  },
  head () {
    const meta: any = {}
    if (this.preload) {
      if (typeof this.preload === 'object') {
        if (this.preload.fetchPriority) {
          meta.fetchPriority = this.preload.fetchPriority
        }

        if (this.preload.tagPriority) {
          meta.tagPriority = this.preload.tagPriority
        }

        if (this.preload.media) {
          meta.media = this.preload.media
        }
      }

      return {
        link: [
          {
            rel: 'preload',
            as: 'image',
            href: this.desktopImage,
            imagesizes: this.srcsetSizes.value,
            imagesrcset: this.srcset.value,
            ...meta
          }
        ]
      }
    }
    return {}
  }
})
