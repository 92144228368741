/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { ObjectDirective } from 'vue'

export const imgPreload: ObjectDirective = {
  bind (el: HTMLImageElement, binding) {
    const { src } = el
    const img = new Image()
    img.sizes = el.sizes
    img.onload = () => {
      el.setAttribute('img-preload-success', 'true')
    }
    img.onerror = () => {
      el.setAttribute('img-preload-error', 'true')
    }
    img.src = src
  }
}
