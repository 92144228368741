
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { StrapiSlider } from '@zaprooecommerce/strapi'
import { useImage } from '~/composables'
import ResponsiveImage from '~/components/molecules/ResponsiveImage.vue'

export default defineComponent({
  name: 'BannerImage',
  components: { ResponsiveImage },
  props: {
    banner: {
      type: Object as PropType<StrapiSlider>,
      default () {
        return {}
      }
    },
    bannerIndex: {
      type: Number,
      default: null
    },
    eagerLoading: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { getMagentoImage } = useImage()

    const generalAttributes = {
      fit: 'cover',
      format: 'webp',
      loading: props.eagerLoading ? 'eager' : 'lazy',
      preload: props.eagerLoading ? { fetchPriority: 'high' } : false
    }

    return { getMagentoImage, generalAttributes }
  }
})
